import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import DIVISIONS from '../../../../constants/divisions.json'

import Placeholder from '../../../common/Placeholder'
import StudentWithAvatar from '../../../students/StudentWithAvatar'

import './Leaderboard.scss'
import colors from '../../../../assets/styles/globals.scss'
import { ReactComponent as GolferIcon } from '../../../../assets/images/common/icons/golfer-half-icon.svg'
import { ReactComponent as FlagIcon } from '../../../../assets/images/common/icons/flag-circle-icon.svg'
import { ReactComponent as MedalIcon } from '../../../../assets/images/common/icons/medal-star-icon.svg'

import { useSelector } from 'react-redux'

const Leaderboard = ({ isModal }) => {
  const event = useSelector(state => state.nineHoleEvent)

  const [leaderboard, setLeaderboard] = useState([])
  const [record, setRecord] = useState({})

  const organizeLeaderboard = useCallback(() => {
    if (!event) {
      return
    }
    const leaderboard = [...DIVISIONS]
    const activities = [...event.students.filter(s => s.status !== 'declined')]
    let record = { score: 999999 }

    if (!activities.length > 0) {
      return
    }

    leaderboard.forEach((division) => {
      division.activities = []
    })
    activities.forEach((activity) => {
      if (activity.score < record.score) {
        record = { ...record, score: activity.score, occurences: 1 }
      } else if (parseInt(activity.score) === record.score) {
        record = { ...record, occurences: record.occurences + 1 }
      }
      leaderboard.forEach((division) => {
        if (
          parseInt(division.yardage, 10) === activity.yardage ||
          _isInRange(division.order, activity.fullTeeYardage)
        ) {
          division.activities.push(activity)
          division.activities.sort((a, b) => a.score - b.score)
        }
      })
    })
    setLeaderboard(leaderboard)
    setRecord(record)
  }, [event])

  const _isInRange = (division_order, yardage) => {
    switch (division_order) {
      case 6:
        if (yardage > 1800 && yardage <= 2300) {
          return true
        } else {
          return false
        }
      case 7:
        if (yardage > 2300 && yardage <= 2600) {
          return true
        } else {
          return false
        }
      case 8:
        if (yardage > 2600 && yardage <= 2900) {
          return true
        } else {
          return false
        }
      case 9:
        if (yardage > 2900 && yardage <= 3200) {
          return true
        } else {
          return false
        }
      case 10:
        if (yardage > 3200) {
          return true
        } else {
          return false
        }
      default:
        return false
    }
  }

  const getMilestones = (activity) => {
    if (isModal) {
      const studentRecord = parseInt(activity.stats[activity.level].record_score) || 0
      if (activity.score === record.score) {
        return <div><MedalIcon fill={colors.darkTeal}/> Medalist</div>
      } else if (activity.score > 8) {
        if (activity.score <= 36) {
          return <div><FlagIcon fill={colors.darkTeal}/> PASSED</div>
        } else if (activity.score && activity.score < studentRecord) {
          return <div><GolferIcon fill={colors.darkTeal}/> New Record</div>
        }
      }
    } else {
      if (activity.milestone === 'event-winner') {
        return <div><MedalIcon fill={colors.darkTeal}/> Medalist</div>
      } else if (activity.milestone === 'record-score') {
        return <div><GolferIcon fill={colors.darkTeal}/> New Record</div>
      } else if (activity.score < 37) {
        return <div><FlagIcon fill={colors.darkTeal}/> PASSED</div>
      }
    }
  }

  const renderLeaderboard = () => (
    leaderboard
      .filter(division => division.activities && division.activities.length > 0)
      .map((division, index) => (
        <table key={index} className='table table-responsive animated fadeIn w-100 leaderboard-table'>
          <thead className='section-header'>
            <tr>
              <th className={`golfer-header division-${division.order}`}>{division.name}</th>
              <th>Score</th>
              <th>Milestone</th>
            </tr>
          </thead>
          <tbody>
            {division.activities.map((activity, index) => (
              <tr
                key={index}
                className={'students-table-row'}
              >
                <td style={{ width: '45%' }} className='golfer-col'>
                  <StudentWithAvatar student={activity} clickable/>
                </td>
                <td style={{ width: '5%' }} className='score-col' >
                  {activity.score || '-'}
                </td>
                <td style={{ width: '15%' }} className='milestones-col'>
                  {getMilestones(activity)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ))
  )

  useEffect(() => {
    organizeLeaderboard()
  }, [organizeLeaderboard])

  return (
    <div id='Leaderboard'>
      <div className='event-leaderboard-container p-4'>
        <h4 className='event-leaderboard-text'>Leaderboard</h4>
        {leaderboard.length > 0 ? (
          renderLeaderboard()
        ) : (
          <div className='col-6 mx-auto mt-5'>
            <Placeholder
              title={'No activites logged for this event'}
              icon={'fa-calendar'}
              subtext1={
                'There have been no activites logged for this event.'
              }
            />
          </div>
        )}
      </div>
    </div>
  )
}

Leaderboard.propTypes = {
  isModal: PropTypes.bool,
}

export default Leaderboard
