import { createSlice, createSelector } from '@reduxjs/toolkit'
import { getRepresentative, getCommunityFeedActivities, getCommunityAllTimeTotals, getCommunityLeaderboardStats, getCommunityLeaderboardRanking, exportCommunityLeaderboardRanking, checkExportStatus } from '../actions/dashboardActions'
import { getScheduleSessionsByMonth } from '../actions/scheduleSessionActions'
import { getEventsByMonth } from '../actions/eventActions'
import { getPrograms, getProgramsFilterOptions } from '../actions/programActions'
import moment from 'moment'
import { DATETIME_FORMAT, getMomentInCommunityTimezoneReversed } from '../utility/datesHelper'
import { getCommunityCategories } from '../actions/programCategoryActions'

const rangeDate = new Date()

export const initialFilters = {
  startDate: new Date(rangeDate.setDate(rangeDate.getDate() - 30)),
  endDate: new Date(),
  courseId: null,
  categoryId: null,
}

const initialState = {
  representative: null,
  sessionsByMonth: {},
  activeCourses: [],
  futureCourses: [],
  optionCourses: [],
  loadingOptionCourses: false,
  selectedMonthDate: new Date(),
  selectedDate: new Date(),
  feedActivities: [],
  loadingFeedActivities: false,
  eventsByMonth: {},
  activitiesTotalPages: 10,
  allTimeTotals: null,
  communityCategories: [],
  loadingCategories: false,
  leaderboardFilters: initialFilters,
  leaderboardRanking: [
    [],
    [],
    [],
    [],
  ],
  rankingTotalPages: [10, 10, 10, 10],
  rankingPages: [1, 1, 1, 1],
  tabIndex: 0,
  leaderboardStats: null,
  leaderboardLoader: false,
  clearFiltersCounter: 0,
  exportUrl: null,
  exportId: null,
  exportError: false,
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setSelectedMonthDate: (state, action) => {
      state.selectedMonthDate = action.payload
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload
    },
    clearActivities: (state) => {
      state.feedActivities = []
    },
    setLeaderboardFilters: (state, action) => {
      state.leaderboardFilters = { ...state.leaderboardFilters, ...action.payload }
      state.leaderboardRanking = [[], [], [], []]
      state.rankingPages = [1, 1, 1, 1]
    },
    setTabIndex: (state, action) => {
      state.tabIndex = action.payload
    },
    incrementFiltersCounter: (state) => {
      state.clearFiltersCounter += 1
    },
    clearExport: (state) => {
      state.exportError = false
      state.exportId = null
      state.exportUrl = null
    },
  },
  extraReducers: {
    [getRepresentative.fulfilled]: (state, action) => {
      state.representative = action.payload.user
    },
    [getScheduleSessionsByMonth.fulfilled]: (state, action) => {
      state.sessionsByMonth[state.selectedMonthDate.getMonth()] = action.payload.scheduleSessions
    },
    [getPrograms.fulfilled]: (state, action) => {
      if (action.payload.filters?.future) {
        state.futureCourses = action.payload.courses
      } else {
        state.activeCourses = action.payload.courses
      }
    },
    [getProgramsFilterOptions.pending]: (state) => {
      state.loadingOptionCourses = true
    },
    [getProgramsFilterOptions.fulfilled]: (state, action) => {
      state.optionCourses = action.payload.courses.map(c => ({ value: c.id, label: c.name, categoryId: c.courseCategoryId }))
      state.loadingOptionCourses = false
    },
    [getCommunityFeedActivities.pending]: (state) => {
      state.loadingFeedActivities = true
    },
    [getCommunityFeedActivities.rejected]: (state) => {
      state.loadingFeedActivities = false
    },
    [getCommunityFeedActivities.fulfilled]: (state, action) => {
      state.activitiesTotalPages = action.payload.meta.pagination.total_pages
      state.feedActivities = [...state.feedActivities, ...action.payload.activities]
      state.loadingFeedActivities = false
    },
    [getEventsByMonth.fulfilled]: (state, action) => {
      state.eventsByMonth[state.selectedMonthDate.getMonth()] = action.payload.events
    },
    [getCommunityAllTimeTotals.fulfilled]: (state, action) => {
      state.allTimeTotals = action.payload
    },
    [getCommunityCategories.pending]: (state) => {
      state.loadingCategories = true
    },
    [getCommunityCategories.fulfilled]: (state, action) => {
      state.communityCategories = action.payload.courseCategories.filter(c => c.name !== 'Other Programs').map(c => ({ value: c.id, label: c.name }))
      state.loadingCategories = false
    },
    [getCommunityLeaderboardStats.fulfilled]: (state, action) => {
      state.leaderboardStats = action.payload
    },
    [getCommunityLeaderboardRanking.pending]: (state) => {
      state.leaderboardLoader = true
    },
    [getCommunityLeaderboardRanking.fulfilled]: (state, action) => {
      const index = action.payload.tabIndex
      state.leaderboardRanking[index] = [...state.leaderboardRanking[index], ...action.payload.leaderboardRanking]
      state.rankingTotalPages[index] = action.payload.meta.pagination.totalPages
      state.rankingPages = state.rankingPages.map((p, i) => i === index ? p + 1 : p)
      state.leaderboardLoader = false
    },
    [getCommunityLeaderboardRanking.rejected]: (state) => {
      state.leaderboardLoader = false
    },
    [exportCommunityLeaderboardRanking.fulfilled]: (state, action) => {
      state.exportError = false
      state.exportId = action.payload.jobId
    },
    [checkExportStatus.fulfilled]: (state, action) => {
      const job = action.payload.job
      if (job.status === 'success') {
        state.exportId = null
        state.exportError = false
        state.exportUrl = job.parsedResult.fileUrl
      }
      if (job.status === 'error') {
        state.exportError = true
        state.exportId = null
      }
    },
  },
})

export const {
  setSelectedDate,
  clearActivities,
  setSelectedMonthDate,
  setLeaderboardFilters,
  setLeaderboardTab,
  setRankingPages,
  setTabIndex,
  incrementFiltersCounter,
  clearExport,
} = dashboardSlice.actions

export default dashboardSlice.reducer

export const sessionsDates = createSelector(
  state => state.dashboard.sessionsByMonth,
  state => state.dashboard.selectedMonthDate,
  state => state.user.current_user.communities[0].timezone,
  (sessions, selectedMonthDate, timezone) =>
    sessions[selectedMonthDate.getMonth()]?.map(s => getMomentInCommunityTimezoneReversed(moment(s.startDate), timezone, DATETIME_FORMAT).toDate())
      || []
)

export const eventsDates = createSelector(
  state => state.dashboard.eventsByMonth,
  state => state.dashboard.selectedMonthDate,
  state => state.user.current_user.communities[0].timezone,
  (events, selectedMonthDate, timezone) =>
    events[selectedMonthDate.getMonth()]?.map(e => getMomentInCommunityTimezoneReversed(moment(e.eventDate), timezone, DATETIME_FORMAT).toDate())
      || []
)

export const selectedDateEvents = createSelector(
  state => state.dashboard.eventsByMonth,
  state => state.dashboard.selectedDate,
  state => state.user.current_user.communities[0].timezone,
  (
    events,
    selectedDate,
    timezone
  ) => {
    const selectedDateEvents = []
    events[selectedDate.getMonth()]?.forEach(e => {
      const studentsProfiles = e.enrolledUsers.map(u => u.avatar?.fileUrl)
      const event = {
        id: e.id,
        seriesName: e.series.title,
        name: e.indexName,
        format: e.format,
        location: e.location,
        date: getMomentInCommunityTimezoneReversed(moment(e.eventDate), timezone, DATETIME_FORMAT).toDate(),
        students: studentsProfiles,
      }
      if (event.date.toDateString() === selectedDate.toDateString()) {
        selectedDateEvents.push(event)
      }
    })

    return selectedDateEvents
  }
)

export const selectedDateSessions = createSelector(
  state => state.dashboard.sessionsByMonth,
  state => state.dashboard.selectedDate,
  state => state.user.current_user.communities[0].timezone,
  (
    sessions,
    selectedDate,
    timezone
  ) => {
    const selectedDateSessions = []
    sessions[selectedDate.getMonth()]?.forEach(s => {
      const enrollmentsProfiles = s.enrolledUsers.map(u => u.avatar?.fileUrl)
      const session = {
        programTitle: s.course.name,
        programId: s.course.id,
        categoryColor: s.course.courseCategory?.color,
        sessionId: s.id,
        enrollments: s.enrolledUsers.length,
        enrollmentsProfiles: enrollmentsProfiles,
      }
      session.sessionTitle = s.title
      session.sessionStartDate = getMomentInCommunityTimezoneReversed(moment(s.startDate), timezone, DATETIME_FORMAT).toDate()
      session.sessionEndDate = getMomentInCommunityTimezoneReversed(moment(s.endDate), timezone, DATETIME_FORMAT).toDate()
      session.location = s.location
      if (session.sessionStartDate.toDateString() === selectedDate.toDateString()) {
        selectedDateSessions.push(session)
      }
    })

    return selectedDateSessions
  }
)
