import React, { useCallback, useEffect, useState } from 'react'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import { useLocation, Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { UncontrolledPopover } from 'reactstrap'
import _ from 'lodash'
import classNames from 'classnames'

import PageHeader from '../common/PageHeader/PageHeader'
import EventForm from './Form/EventForm'
import ConfirmationModal2 from '../common/ConfirmationModal2'
import Series from './Series'
import CompletedEvents from './CompletedEvents'

import styles from './Events.module.scss'
import { ReactComponent as TableSelectDropDownIcon } from '../../assets/images/common/icons/table-select-drop-down-icon.svg'
import { ReactComponent as EditIcon } from '../../assets/images/common/icons/programBuilder/edit-icon.svg'

import { useSelector, useDispatch } from 'react-redux'
import { eventFormData, resetState, setField } from '../../reducers/nineHoleEventReducer'
import * as commonActions from '../../actions/commonActions'
import * as eventActions from '../../actions/eventActions'
import * as constants from '../../misc/Constants'

const EditIconComponent = (
  <div className={styles.editIconWrapper}>
    <EditIcon fill='#fdfdfd' width={'40px'} height={'40px'}/>
  </div>
)

const Events = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const currentUser = useSelector(state => state.user.current_user)
  const event = useSelector(state => state.nineHoleEvent)
  const formData = useSelector(eventFormData)
  const communityStudents = useSelector(state => state.communityStudents.students)

  const [showForm, setShowForm] = useState(false)
  const [eventLoader, setEventLoader] = useState(false)
  const [showLoadScoresModal, setShowLoadScoresModal] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)
  const [searchEvents, setSearchEvents] = useState('')
  const [searchUpcomingSeries, setSearchUpcomingSeries] = useState('')
  const [searchCompletedSeries, setSearchCompletedSeries] = useState('')
  const [activePageUpcomingSeries, setActivePageUpcomingSeries] = useState(1)
  const [activePageCompletedSeries, setActivePageCompletedSeries] = useState(1)
  const [activePageEvents, setActivePageEvents] = useState(1)


  const localScores = JSON.parse(localStorage.getItem('draftScores'))
  const eventScores = localScores && localScores[-1] && localScores[-1]['scores']

  const fetchEvents = useCallback((filters) => {
    dispatch(
      eventActions.getEvents(currentUser.id, filters, activePageEvents, constants.PER_PAGE_DEFAULT)
    ).then(() => {
      setActivePageEvents(1)
    })
  }, [dispatch, currentUser.id, activePageEvents])


  const createEvent = () => {
    setEventLoader(true)
    dispatch(eventActions.createEvent(formData)).then(() => {
      toast.success(`Success! Created ${event.name}`, {
        position: toast.POSITION.BOTTOM_LEFT,
      })
      removeDraftScores()
      _.delay(handleEventSubmittedClick, 5000)
      setEventLoader(false)
    }).catch(() => setEventLoader(false))
  }

  const handleEventSubmittedClick = () => {
    setShowForm(false)
    fetchEvents()
  }

  const quickLog = () => {
    dispatch(resetState())
    eventScores ? setShowLoadScoresModal(true) : setShowForm(true)
  }

  const loadScores = () => {
    setShowLoadScoresModal(false)
    const updatedStudents = []
    const studentErrors = []
    Object.keys(eventScores).forEach((userId) => {
      const studentIndex = communityStudents.findIndex(std => std.id === parseInt(userId))
      const std = communityStudents[studentIndex]
      const savedData = eventScores[userId]

      if (!std) {
        studentErrors.push(`Student ${savedData['first_name']} ${savedData['last_name']} could not be loaded.`)
      } else {
        updatedStudents.push({
          user_id: std.id,
          first_name: std.first_name,
          last_name: std.last_name,
          current_formal_division: std.current_formal_division,
          stats: std.stats,
          avatar: std.avatar,
          addedFrom: std.addedFrom,
          score: savedData['score'],
          greens: savedData['greens'],
          putts: savedData['putts'],
          yardage: savedData['yardage'],
          fullTeeYardage: savedData['fullTeeYardage'],
          level: savedData['level'],
        })
      }
    })

    if (studentErrors) {
      dispatch(setField({ field: 'errors', value: { students: studentErrors } }))
    }
    dispatch(setField({ field: 'students', value: updatedStudents }))
    dispatch(setField({ field: 'action', value: 'Log Scores' }))
    setShowForm(true)
  }

  const removeDraftScores = () => {
    const localScores = JSON.parse(localStorage.getItem('draftScores'))
    delete localScores[event.id]
    localStorage.setItem('draftScores', JSON.stringify(localScores))
  }

  const startFromScratch = () => {
    removeDraftScores()
    setShowLoadScoresModal(false)
    setShowForm(true)
  }

  const closeForm = () => {
    dispatch(resetState())
    setShowForm(false)
  }

  useEffect(() => {
    dispatch(commonActions.setCurrentTab('Events'))
  }, [dispatch])

  useEffect(() => {
    if (!_.isEmpty(location.state) && location.state.smartToggle) {
      setShowForm(true)
    }
  }, [location.state])

  useEffect(() => {
    dispatch(commonActions.showSideBar(!showForm))
    dispatch(commonActions.showNavBar(!showForm))
  }, [dispatch, showForm])

  const HeaderActionButton = (
    <div>
      <button id='logEventButton' className='btn btn-primary pl-3 pr-3'>
        <div className='d-flex align-items-center'>
          <span className='mr-2'>Create Event</span>
          <TableSelectDropDownIcon stroke='#fff' style={{ marginLeft: '2px' }}/>
        </div>
      </button>
      <UncontrolledPopover
        placement='bottom'
        target='logEventButton'
        trigger='legacy'
      >
        <div className={classNames('d-flex flex-column', styles['header-button-actions'])}>
          <Link to={'/series/new'}>Create Event Series</Link>
          <p aria-hidden='true' onClick={() => quickLog()}>Quick Log an Event</p>
        </div>
      </UncontrolledPopover>
    </div>
  )

  return (
    <div className={styles['events-page']} style={{ marginLeft: !showForm ? '-15px' : '0' }} >
      { showForm ?
        <EventForm
          isQuickLog
          onClickBack={closeForm}
          createEvent={createEvent}
          handleEventSubmittedClick={handleEventSubmittedClick}
          eventLoader={eventLoader}
        /> :
        <div>
          <PageHeader
            title='9 Hole Events'
            actionComponent={HeaderActionButton}
          />

          <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
            <TabList className={styles.tabList}>
              <Tab className={`${styles.tab} react-tabs__tab`} selectedClassName={styles.activeTab}>Upcoming Series</Tab>
              <Tab className={`${styles.tab} react-tabs__tab`} selectedClassName={styles.activeTab}>Completed Series</Tab>
              <Tab className={`${styles.tab} react-tabs__tab`} selectedClassName={styles.activeTab}> Completed Events</Tab>
            </TabList>
            <TabPanel className={styles.tabPanel}>
              <Series
                typeCompleted={false}
                searchTerm={searchUpcomingSeries}
                setSearchTerm={setSearchUpcomingSeries}
                activePage={activePageUpcomingSeries}
                setActivePage={setActivePageUpcomingSeries}
                loadScores={loadScores}
              />
            </TabPanel>
            <TabPanel className={styles.tabPanel}>
              <Series
                typeCompleted={true}
                searchTerm={searchCompletedSeries}
                setSearchTerm={setSearchCompletedSeries}
                activePage={activePageCompletedSeries}
                setActivePage={setActivePageCompletedSeries}
                loadScores={loadScores}
              />
            </TabPanel>
            <TabPanel className={styles.tabPanel}>
              <CompletedEvents
                quickLog={quickLog}
                showform={showForm}
                searchTerm={searchEvents}
                setSearchTerm={setSearchEvents}
                activePage={activePageEvents}
                setActivePage={setActivePageEvents}
                loadScores={loadScores}
              />
            </TabPanel>
          </Tabs>
        </div>
      }
      <ConfirmationModal2
        showModal={showLoadScoresModal}
        closeModal={() => setShowLoadScoresModal(false)}
        onDeny={startFromScratch}
        denyMessage='Start from Scratch'
        onProceed={loadScores}
        proceedMessage='Load Scores'
        icon={EditIconComponent}
        title='Load draft scores?'
        message='There are draft scores saved for this event. Do you want to load them?'
      />
    </div>
  )
}
export default Events
